import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import {
  validateEachType,
  validateEachTypeQuantity,
} from "../../utils/validateEachType";
import messages from "../../errorTypes/Types.ts";

const Rura = ({ density, onWeightChange }) => {
  const [diameter, setDiameter] = useState("");
  const [thickness, setThickness] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorDiameter, setErrorDiameter] = useState(null);
  const [errorThickness, setErrorThickness] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const formattedDiameter = diameter.replace(",", ".");
    const formattedThickness = thickness.replace(",", ".");
    const formattedLength = length.replace(",", ".");
    const diameter_m = Number(formattedDiameter) / 1000; // mm -> m
    const thickness_m = Number(formattedThickness) / 1000; // mm -> m
    const length_m = Number(formattedLength) / 1000; // mm -> m
    const density_kg_m3 = density * 1000; // g/cm^3 -> kg/m^3
    const outerRadius = diameter_m / 2; // mm
    const innerRadius = outerRadius - thickness_m; // mm
    // volume (m^3)
    const volume =
      Math.PI *
      (outerRadius ** 2 - innerRadius ** 2) *
      length_m *
      density_kg_m3 *
      count;
    const weightPerMeter = volume / length_m; // mass of 1m in kg
    const totalWeight = volume; // totalWeight in kg

    onWeightChange({
      totalWeight: totalWeight.toFixed(3),
      totalWeightPerKg: weightPerMeter.toFixed(3),
    });
  }, [diameter, thickness, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="diameter-input">[A] Wpisz średnicę (mm)</label>
        <Input
          id="diameter-input"
          type="text"
          autoComplete="off"
          value={diameter}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const inputValue = e.target.value.replace(".", ",");
            const formattedValue =
              inputValue === null || inputValue === undefined
                ? "0"
                : inputValue;
            const integerPart = formattedValue.split(",")[0];
            const decimalPart = formattedValue.split(",")[1];

            if (integerPart && integerPart.length > 6) {
              setErrorDiameter(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorDiameter(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorDiameter(messages.unicError.empty);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorDiameter(messages.unicError.invalid);
              return;
            }
            setDiameter(formattedValue);
            setErrorDiameter(null);
          }}
          onBlur={() => {
            if (!validateEachType(diameter)) {
              setErrorDiameter(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setDiameter)}
        />
        {errorDiameter && <p className="text-error-text">{errorDiameter}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="thickness-input">[B] Wpisz grubość ścianki (mm)</label>
        <Input
          id="thickness-input"
          type="text"
          autoComplete="off"
          value={thickness}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const inputValue = e.target.value.replace(".", ",");
            const formattedValue =
              inputValue === null || inputValue === undefined
                ? "0"
                : inputValue;
            const integerPart = formattedValue.split(",")[0] || "0";
            const decimalPart = formattedValue.split(",")[1] || "0";
            if (integerPart && integerPart.length > 6) {
              setErrorThickness(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorThickness(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorThickness(messages.unicError.empty);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorThickness(messages.unicError.invalid);
              return;
            }
            setThickness(formattedValue);
            setErrorThickness(null);
          }}
          onBlur={() => {
            if (!validateEachType(thickness)) {
              setErrorThickness(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setThickness)}
        />
        {errorThickness && <p className="text-error-text">{errorThickness}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[C] Wpisz długość rury (mm)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const inputValue = e.target.value.replace(".", ",");
            const formattedValue =
              inputValue === null || inputValue === undefined
                ? "0"
                : inputValue;
            const integerPart = formattedValue.split(",")[0] || "0";
            const decimalPart = formattedValue.split(",")[1] || "0";
            if (integerPart && integerPart.length > 6) {
              setErrorLength(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorLength(messages.unicError.maxDecimal);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorLength(messages.unicError.invalid);
              return;
            }
            setLength(formattedValue);
            setErrorLength(null);
          }}
          onBlur={() => {
            if (!validateEachType(length)) {
              setErrorLength(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const inputValue = e.target.value.trim();
            const formattedValue =
              inputValue === null || inputValue === undefined
                ? "0"
                : inputValue;
            if (formattedValue.length > 6) {
              setErrorCount(messages.unicErrorQuantity.maxInteger);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorCount(messages.unicErrorQuantity.empty);
              return;
            }
            if (!validateEachTypeQuantity(formattedValue)) {
              setErrorCount(messages.unicErrorQuantity.invalid);
              return;
            }
            setCount(formattedValue);
            setErrorCount(null);
          }}
          onBlur={() => {
            if (!validateEachTypeQuantity(count)) {
              setErrorCount(messages.unicErrorQuantity.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default Rura;

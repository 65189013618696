import React from "react";
import css from "./Post.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
export const Post = ({ title, desc, imgpath, data, link }) => {
  const now = new Date();
  const timestamp = now.getTime();

  return (
    <div className={css.post}>
      <div className="w-full">
        <LazyLoadImage
          src={imgpath}
          alt={`Article-${timestamp}`}
          effect="blur"
          width={"100%"}
          height={"100%"}
        />
      </div>
      <div className={css.category}>PORADNIK</div>
      <div className={css.posttitle}>{title}</div>

      <ul className="w-full">
        <li className={css.postdata}>{data}</li>
      </ul>

      <div className="flex flex-col items-start sm:h-auto">
        <p className={css.postdesc}>{desc}</p>
        <a href={link} className={css.link} target="_blank" rel="noreferrer">
          Dowiedz się więcej
        </a>
      </div>
    </div>
  );
};

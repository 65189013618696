import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import messages from "../../errorTypes/Types.ts";
import {
  validateEachType,
  validateEachTypeQuantity,
} from "../../utils/validateEachType";

const ProfilZamknietyKwadratowy = ({ density, onWeightChange }) => {
  const [width, setWidth] = useState("");
  const [thickness, setThickness] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState("1");

  const [errorWidth, setErrorWidth] = useState(null);
  const [errorThickness, setErrorThickness] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const formattedWidth = width.replace(",", ".");
    const formattedThickness = thickness.replace(",", ".");
    const formattedLength = length.replace(",", ".");
    const width_m =
      formattedWidth.trim() === "" ? 0 : parseFloat(formattedWidth) / 1000; // mm to m
    const thickness_m =
      formattedThickness.trim() === ""
        ? 0
        : parseFloat(formattedThickness) / 1000; // mm to m
    const length_m =
      formattedLength.trim() === "" ? 0 : Number(formattedLength); // m
    const outerVolume = Math.pow(width_m, 2); //
    const innerVolume = Math.pow(width_m - 2 * thickness_m, 2); //
    const volume = outerVolume - innerVolume; //
    const density_kg_m3 = density * 1000; // kg/m^3

    const weightPerMeter = volume * Number(density_kg_m3) * Number(count); //
    const totalWeight = weightPerMeter * length_m * Number(count); //
    onWeightChange({
      totalWeight: totalWeight.toFixed(3),
      totalWeightPerKg: weightPerMeter.toFixed(3),
    });
  }, [width, thickness, length, count, density]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="width-input">[A] Wpisz szerokość profilu(mm)</label>
        <Input
          id="width-input"
          type="text"
          autoComplete="off"
          value={width}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const inputValue = e.target.value.replace(".", ",");
            const formattedValue =
              inputValue === null || inputValue === undefined
                ? "0"
                : inputValue;
            const integerPart = formattedValue.split(",")[0] || "0";
            const decimalPart = formattedValue.split(",")[1] || "0";
            if (integerPart && integerPart.length > 6) {
              setErrorWidth(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorWidth(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorWidth(messages.unicError.empty);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorWidth(messages.unicError.invalid);
              return;
            }
            setWidth(formattedValue);
            setErrorWidth(null);
          }}
          onBlur={() => {
            if (!validateEachType(width)) {
              setErrorWidth(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setWidth)}
        />
        {errorWidth && <p className="text-error-text">{errorWidth}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="thickness-input">[B] Wpisz grubość ścianki (mm)</label>
        <Input
          id="thickness-input"
          type="text"
          autoComplete="off"
          value={thickness}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const inputValue = e.target.value.replace(".", ",");

            const formattedValue =
              inputValue === null || inputValue === undefined
                ? "0"
                : inputValue;
            const integerPart = formattedValue.split(",")[0] || "0";
            const decimalPart = formattedValue.split(",")[1] || "0";
            if (integerPart && integerPart.length > 6) {
              setErrorThickness(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorThickness(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorThickness(messages.unicError.empty);
              return;
            }

            if (!validateEachType(formattedValue)) {
              setErrorThickness(messages.unicError.invalid);
              return;
            }
            setThickness(formattedValue);
            setErrorThickness(null);
          }}
          onBlur={() => {
            if (!validateEachType(thickness)) {
              setErrorThickness(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setThickness)}
        />
        {errorThickness && <p className="text-error-text">{errorThickness}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[C] Wpisz długość profilu (m)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const inputValue = e.target.value.replace(".", ",");
            const formattedValue =
              inputValue === null || inputValue === undefined
                ? "0"
                : inputValue;
            const integerPart = formattedValue.split(",")[0] || "0";
            const decimalPart = formattedValue.split(",")[1] || "0";
            if (integerPart && integerPart.length > 6) {
              setErrorLength(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorLength(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorLength(messages.unicError.empty);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorLength(messages.unicError.invalid);
              return;
            }
            setLength(formattedValue);
            setErrorLength(null);
          }}
          onBlur={() => {
            if (!validateEachType(length)) {
              setErrorLength(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const inputValue = e.target.value.trim();
            const formattedValue =
              inputValue === null || inputValue === undefined
                ? "0"
                : inputValue;
            if (formattedValue.length > 6) {
              setErrorCount(messages.unicErrorQuantity.maxInteger);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorCount(messages.unicErrorQuantity.empty);
              return;
            }
            if (!validateEachTypeQuantity(formattedValue)) {
              setErrorCount(messages.unicErrorQuantity.invalid);
              return;
            }
            setCount(formattedValue);
            setErrorCount(null);
          }}
          onBlur={() => {
            if (!validateEachTypeQuantity(count)) {
              setErrorCount(messages.unicErrorQuantity.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default ProfilZamknietyKwadratowy;

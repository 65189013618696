import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import {
  validateEachType,
  validateEachTypeQuantity,
} from "../../utils/validateEachType";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import messages from "../../errorTypes/Types.ts";

const Katowniki = ({ density, onWeightChange }) => {
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [zBox, setZBox] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorX, setErrorX] = useState(null);
  const [errorY, setErrorY] = useState(null);
  const [errorZBox, setErrorZBox] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const formattedX = x.replace(",", ".");
    const formattedY = y.replace(",", ".");
    const formattedZBox = zBox.replace(",", ".");
    const formattedLength = length.replace(",", ".");
    const x_m = Number(formattedX) / 1000; // mm -> m width
    const y_m = Number(formattedY) / 1000; // mm -> m height
    const zBox_m = Number(formattedZBox) / 1000; // mm -> m thickness
    const length_m = Number(formattedLength); // m
    const density_kg_m3 = density * 1000; // kg/m^3

    const volume =
      x_m * zBox_m * length_m +
      y_m * zBox_m * length_m -
      zBox_m * zBox_m * length_m; // m^3
    const weight = volume * density_kg_m3 * count; // kg
    const weightPerMeter = weight / length_m; // kg/m

    onWeightChange({
      totalWeight: weight.toFixed(3),
      totalWeightPerKg: weightPerMeter.toFixed(3),
    });
  }, [x, y, zBox, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="x-input">[A] Wpisz szerokość (mm)</label>
        <Input
          id="x-input"
          type="text"
          autoComplete="off"
          value={x}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const formattedValue = e.target.value.replace(".", ",");
            const integerPart = formattedValue.split(",")[0];
            const decimalPart = formattedValue.split(",")[1];
            if (integerPart.length > 6) {
              setErrorX(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorX(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorX(messages.unicError.empty);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorX(messages.unicError.invalid);
              return;
            }
            setX(formattedValue);
            setErrorX(null);
          }}
          onBlur={() => {
            if (!validateEachType(x)) {
              setErrorX(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setX)}
        />
        {errorX && <p className="text-error-text">{errorX}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="y-input">[B] Wpisz wysokość (mm)</label>
        <Input
          id="y-input"
          type="text"
          autoComplete="off"
          value={y}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const formattedValue = e.target.value.replace(".", ",");
            const integerPart = formattedValue.split(",")[0];
            const decimalPart = formattedValue.split(",")[1];
            if (integerPart.length > 6) {
              setErrorY(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorY(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorY(messages.unicError.empty);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorY(messages.unicError.invalid);
              return;
            }
            setY(formattedValue);
            setErrorY(null);
          }}
          onBlur={() => {
            if (!validateEachType(y)) {
              setErrorY(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setY)}
        />
        {errorY && <p className="text-error-text">{errorY}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="zBox-input">[C] Wpisz grubość ścianki (mm)</label>
        <Input
          id="zBox-input"
          type="text"
          autoComplete="off"
          value={zBox}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const formattedValue = e.target.value.replace(".", ",");
            const integerPart = formattedValue.split(",")[0];
            const decimalPart = formattedValue.split(",")[1];
            if (integerPart.length > 6) {
              setErrorZBox(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorZBox(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorZBox(messages.unicError.empty);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorZBox(messages.unicError.invalid);
              return;
            }
            setZBox(formattedValue);
            setErrorZBox(null);
          }}
          onBlur={() => {
            if (!validateEachType(zBox)) {
              setErrorZBox(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setZBox)}
        />
        {errorZBox && <p className="text-error-text">{errorZBox}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[D] Wpisz długość kątownika (m)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const formattedValue = e.target.value.replace(".", ",");
            const integerPart = formattedValue.split(",")[0];
            const decimalPart = formattedValue.split(",")[1];
            if (integerPart.length > 6) {
              setErrorLength(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorLength(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorLength(messages.unicError.empty);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorLength(messages.unicError.invalid);
              return;
            }
            setLength(formattedValue);
            setErrorLength(null);
          }}
          onBlur={() => {
            if (!validateEachType(length)) {
              setErrorLength(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const inputValue = e.target.value.trim();
            const formattedValue =
              inputValue === null || inputValue === undefined
                ? "0"
                : inputValue;
            if (formattedValue.length > 6) {
              setErrorCount(messages.unicErrorQuantity.maxInteger);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorCount(messages.unicErrorQuantity.empty);
              return;
            }
            if (!validateEachTypeQuantity(formattedValue)) {
              setErrorCount(messages.unicErrorQuantity.invalid);
              return;
            }
            setCount(formattedValue);
            setErrorCount(null);
          }}
          onBlur={() => {
            if (!validateEachTypeQuantity(count)) {
              setErrorCount(messages.unicErrorQuantity.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default Katowniki;

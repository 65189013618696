const messages = {
  unicError: {
    maxInteger: "Max liczba cyfr przed przecinkiem: 6",
    maxDecimal: "Max liczba cyfr po przecinku: 3",
    empty: "Pole jest wymagane",
    invalid: "Niepoprawny format",
  },
  unicErrorQuantity: {
    maxInteger: "Max liczba cyfr: 6",
    maxDecimal: "Wartość bez części dziesiętnej",
    empty: "Ilość jest wymagana",
    invalid: "Niepoprawny format wpisanej ilości",
  },
};

export default messages;

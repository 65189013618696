import React, { useState, useEffect, useCallback } from "react";
import { Input } from "@nextui-org/input";
import { onKeyDown } from "../../utils/onKeyDownFunction";
import {
  validateEachType,
  validateEachTypeQuantity,
} from "../../utils/validateEachType";
import messages from "../../errorTypes/Types.ts";

const PretOkraglyAlu = ({ density, onWeightChange }) => {
  const [diameter, setDiameter] = useState("");
  const [length, setLength] = useState("");
  const [count, setCount] = useState(1);

  const [errorDiameter, setErrorDiameter] = useState(null);
  const [errorLength, setErrorLength] = useState(null);
  const [errorCount, setErrorCount] = useState(null);

  const calculateWeight = useCallback(() => {
    const formattedDiameter = diameter.replace(",", ".");
    const formattedLength = length.replace(",", ".");
    const radius_m = Number(formattedDiameter) / 2000; // mm -> m
    const density_kg_m3 = density * 1000; // kg/m^3
    const volumeKg =
      Math.PI *
      Math.pow(radius_m, 2) *
      Number(formattedLength) *
      density_kg_m3 *
      count; // Masa // mm^2 * m * kg/m^3 * szt.
    const volumeMeter = volumeKg / Number(formattedLength); // kg/m
    onWeightChange({
      totalWeight: volumeKg.toFixed(3),
      totalWeightPerKg: volumeMeter.toFixed(3),
    });
  }, [diameter, length, count, density, onWeightChange]);

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateWeight();
    }, 100); // Opóźnienie 100ms

    return () => {
      clearTimeout(handler);
    };
  }, [calculateWeight]);

  return (
    <div className="obliczenia">
      <div className="obliczenia-inputs">
        <label htmlFor="diameter-input">[A] Wpisz średnicę pręta (mm)</label>
        <Input
          id="diameter-input"
          type="text"
          autoComplete="off"
          value={diameter}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const formattedValue = e.target.value.replace(".", ",");
            const integerPart = formattedValue.split(",")[0];
            const decimalPart = formattedValue.split(",")[1];
            if (integerPart && integerPart.length > 6) {
              setErrorDiameter(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorDiameter(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorDiameter(messages.unicError.empty);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorDiameter(messages.unicError.invalid);
              return;
            }
            setDiameter(formattedValue);
            setErrorDiameter(null);
          }}
          onBlur={() => {
            if (!validateEachType(diameter)) {
              setErrorDiameter(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setDiameter)}
        />
        {errorDiameter && <p className="text-error-text">{errorDiameter}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="length-input">[B] Wpisz długość pręta (m)</label>
        <Input
          id="length-input"
          type="text"
          autoComplete="off"
          value={length}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const formattedValue = e.target.value.replace(".", ",");
            const integerPart = formattedValue.split(",")[0];
            const decimalPart = formattedValue.split(",")[1];
            if (integerPart && integerPart.length > 6) {
              setErrorLength(messages.unicError.maxInteger);
              return;
            }
            if (decimalPart && decimalPart.length > 3) {
              setErrorLength(messages.unicError.maxDecimal);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorLength(messages.unicError.empty);
              return;
            }
            if (!validateEachType(formattedValue)) {
              setErrorLength(messages.unicError.invalid);
              return;
            }
            setLength(formattedValue);
            setErrorLength(null);
          }}
          onBlur={() => {
            if (!validateEachType(length)) {
              setErrorLength(messages.unicError.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setLength)}
        />
        {errorLength && <p className="text-error-text">{errorLength}</p>}
      </div>
      <div className="obliczenia-inputs">
        <label htmlFor="count-input">Wpisz ilość (szt.)</label>
        <Input
          id="count-input"
          type="text"
          autoComplete="off"
          value={count}
          classNames={{
            inputWrapper:
              "bg-transparent rounded-none group-data-[focus=true]:bg-transparent data-[hover=true]:bg-transparent px-0",
            input: "h-9",
          }}
          onChange={(e) => {
            const inputValue = e.target.value.trim();
            const formattedValue =
              inputValue === null || inputValue === undefined
                ? "0"
                : inputValue;
            if (formattedValue.length > 6) {
              setErrorCount(messages.unicErrorQuantity.maxInteger);
              return;
            }
            if (!formattedValue.trim()) {
              setErrorCount(messages.unicErrorQuantity.empty);
              return;
            }
            if (!validateEachTypeQuantity(formattedValue)) {
              setErrorCount(messages.unicErrorQuantity.invalid);
              return;
            }
            setCount(formattedValue);
            setErrorCount(null);
          }}
          onBlur={() => {
            if (!validateEachTypeQuantity(count)) {
              setErrorCount(messages.unicErrorQuantity.empty);
              return;
            }
          }}
          onKeyDown={(e) => onKeyDown(e, setCount)}
        />
        {errorCount && <p className="text-error-text">{errorCount}</p>}
      </div>
    </div>
  );
};

export default PretOkraglyAlu;

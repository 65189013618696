import css from "./Home.module.css";
import Header from "../../components/Header/Header";
import { useState } from "react";
import Aluminium from "../Aluminium/Aluminium";

import gestoscalu from "../../data/gestoscalu.json";
import gestoscstali from "../../data/gestoscstali.json";
import gestoscnierdzewnej from "../../data/gestoscnierdzewnej.json";
import RightBar from "../../components/RightBar/RightBar";
import ButtomBar from "../../components/ButtomBar/ButtomBar";
import MobileTopBar from "../../components/MobileTopBar/MobileTopBar";
import { Post } from "../../components/Post/Post";

const Home = () => {
  const [activeComponent, setActiveComponent] = useState("AluList");
  const [density, setDensity] = useState(gestoscalu[0].value);
  // const [density, setDensity] = useState("2.7");
  const posts = [
    {
      id: 0,
      title: "ILE WAŻĄ PRĘTY ZBROJENIOWE?",
      desc: "Pręty zbrojeniowe to jeden z kluczowych elementów konstrukcyjnych stosowanych w budownictwie.",
      imgpath:
        "https://www.kalkulatorstali.com/blog/wp-content/uploads/2024/08/ile-waza-prety-zbrojeniowe-768x513.jpg",
      date: "30 sierpnia, 2024",
      link: "https://www.kalkulatorstali.com/blog/2024/08/30/ile-waza-prety-zbrojeniowe/",
    },
    {
      id: 1,
      title: "NAJCZĘŚCIEJ ZADAWANE PYTANIA",
      desc: "W tym artykule zebraliśmy dla Ciebie najczęściej zadawane pytania. Starannie opracowaliśmy poniższy zestaw pytań i odpowiedzi.",
      imgpath:
        "https://www.kalkulatorstali.com/blog/wp-content/uploads/2024/08/najczesciej-zadawane-pytania-768x513.jpg",
      date: "30 sierpnia, 2024",
      link: "https://www.kalkulatorstali.com/blog/2024/08/30/najczesciej-zadawane-pytania/",
    },
    {
      id: 2,
      title: "RODZAJE I WŁAŚCIWOŚCI …",
      desc: "Dwuteowniki to jedne z najważniejszych elementów konstrukcyjnych stosowanych w budownictwie i przemyśle.",
      imgpath:
        "https://www.kalkulatorstali.com/blog/wp-content/uploads/2024/09/worker-is-controlling-process-rail-cutting-busy-metal-factory-768x512.jpg",
      date: "26 września, 2024",
      link: "https://www.kalkulatorstali.com/blog/2024/09/26/rodzaje-i-wlasciwosci-dwuteownikow/",
    },
  ];

  const sortedPosts = posts.sort((a, b) => b.id - a.id);

  return (
    <>
      <Header />
      <main>
        <div className="inner-main">
          <MobileTopBar />
          <div className={css.buttonList}>
            <button
              onClick={() => {
                setActiveComponent("AluList");
                setDensity(gestoscalu[0].value);
              }}
              className={`${css.button} ${
                activeComponent === "AluList" ? css.buttonActive : ""
              }`}
            >
              Aluminium
            </button>
            <button
              onClick={() => {
                setActiveComponent("stal");
                setDensity(gestoscstali[0].value);
              }}
              className={`${css.button} ${
                activeComponent === "stal" ? css.buttonActive : ""
              }`}
            >
              STAL
            </button>
            <button
              onClick={() => {
                setActiveComponent("StalList");
                setDensity(gestoscnierdzewnej[0].value);
              }}
              className={`${css.button} ${
                activeComponent === "StalList" ? css.buttonActive : ""
              }`}
            >
              Stal Nierdzewna
            </button>
          </div>
          <Aluminium
            activeComponent={activeComponent}
            density={density}
            setDensity={setDensity}
          />
        </div>
        <RightBar />
      </main>
      <div className={css.outerPostsConatiner}>
        <div className={css.posts}>
          {sortedPosts.map((post) => (
            <Post
              key={post.id}
              title={post.title}
              desc={post.desc}
              imgpath={post.imgpath}
              data={post.date}
              link={post.link}
            />
          ))}
        </div>
      </div>
      <ButtomBar />
    </>
  );
};

export default Home;

// -------------------------------------------
export const validateEachType = (text) => {
  const regex = /^[1-9]\d{0,5}(,\d{0,3})?$/;
  return regex.test(text);
};

export const validateEachTypeQuantity = (text) => {
  const regex = /^[1-9]\d{0,5}$/;
  return regex.test(text);
};
